import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { BackLink } from '../../components/ServiceUI/Core/Links/Links';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { appConstants } from '../../helper/client/constant';
import { getRefData } from '../../helper/getRefData';
import { setShowError } from '../../reducers/errorHandling';
import { useNavigate } from 'react-router-dom';

import content from './Content/NewRequestView.json';
import { ViewSR } from '../../components/ServiceUI/ServiceRequest/ViewSR/ViewSR';
import KeyRequestInfo from '../../components/ServiceUI/ServiceRequest/KeyRequestInfo/KeyRequestInfo';
import { getServiceRequest, getAttachments, getWorkType, getSubWorkType } from './API/srApiService';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { getSelectedDescription } from './Helper/srHelperFunc';

export const SRNewRequestViewPage: React.FC = () => {
  React.useEffect(() => {
    document.title = 'View Service Request';
  }, []);
  let caseID = new URLSearchParams(document.location.search).get('CASEID');

  const [srInfo, setSrInfo] = React.useState<any>();
  const [srRequestInfo, setSrRequestInfo] = React.useState<any>({});
  const [attachmentInfo, setAttachmentInfo] = useState([]);
  const [workType, setWorkType] = useState('');
  const [subWorkType, setSubWorkType] = useState('');
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //get caseID and display SR details
    if (caseID) {
      setShowSpinnerApiIsInProgress(true);
      getServiceRequest(caseID, '')
        .then((res) => {
          setShowSpinnerApiIsInProgress(false);
          setSrInfo(res.data);
          let SRType =
            res.data.content.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE
              ? 'WorkType'
              : 'serviceType';

          getWorkTypeDesc(SRType, res.data.content.ServiceRequest.WorkType);
          if (SRType) {
            const workSubTypeCode = res.data.content.ServiceRequest.WorkSubType;
            getSubWorkTypeDesc(res.data.content.ServiceRequest.WorkType, workSubTypeCode);
          }

          const pegaRes = res.data.content.ServiceRequest;
          const promise1 = getRefData('SR', 'servicetype', res.data.content.ServiceRequest.ServiceType);
          const promise2 = getRefData('SR', 'groupdiv', res.data.content.ServiceRequest.PrimaryContact.Service);
          Promise.all([promise1, promise2])
            .then((values) => {
              const newObj = { ...pegaRes, ServiceType: values[0], Service: values[1] }; //not sure about Service - check this
              setSrRequestInfo(newObj);
            })

            .catch((error) => {
              dispatch(setShowError({ hasError: true, error }));
            });
        })
        .catch((error) => {
          setShowSpinnerApiIsInProgress(false);
          dispatch(setShowError({ hasError: true, error }));
        });

      //get attachments section
      getAttachments(caseID)
        .then((res) => {
          const finalArr = res.data.attachments.filter(
            (file) =>
              file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE &&
              file.category !== appConstants.ATTACHMENTS_FILE_CATEGORY,
          );
          setAttachmentInfo(finalArr);
          setShowSpinnerApiIsInProgress(false);
        })
        .catch((error) => {
          setShowSpinnerApiIsInProgress(false);
          dispatch(setShowError({ hasError: true, error }));
        });
    }
  }, []);

  //get worktype description
  const getWorkTypeDesc = (type, selectedCode) => {
    getWorkType(type)
      .then((res) => {
        let selectedDescription = 'Select';
        selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
        setWorkType(selectedDescription);
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  //get worktype description
  const getSubWorkTypeDesc = (currentworkType, selectedCode) => {
    getSubWorkType(currentworkType)
      .then((res) => {
        let selectedDescription = 'Select';
        selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
        setSubWorkType(selectedDescription);
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  return (
    <>
      <Header {...content.header} mainHeading={srRequestInfo ? srRequestInfo.RequestTitle : 'View Request'} />
      <SubContainer verticalMargin="1rem">
        {/* View Request Information  */}
        <Box>
          <Heading as="h3" fontSize="sm" fontWeight="medium">
            View Request
          </Heading>
          <Text fontWeight="light">Please ensure the details provided below are correct and current.</Text>
        </Box>

        <BackLink text="Back to homepage" href=".." />
        <>{showSpinnerApiIsInProgress && <Spinner />}</>
        {srInfo ? (
          <>
            <KeyRequestInfo variant="submitted" srInfo={srInfo} />
            <ViewSR SR={srRequestInfo} Attachments={attachmentInfo} srWorktype={workType} srSubWorkType={subWorkType} />
          </>
        ) : (
          <>No data found for this case</>
        )}
        <Box display="flex" alignItems="center" gap={3} paddingTop="1.5rem">
          <Button variant="tertiary" onClick={() => navigate('/my-service-request')}>
            Cancel
          </Button>
        </Box>
      </SubContainer>
    </>
  );
};
