import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { ViewPIR } from '../../components/ServiceUI/EFT/ViewPIR/ViewPIR';
import { appConstants } from '../../helper/client/constant';
import { setShowError } from '../../reducers/errorHandling';
import { PIRCaseAudit } from './API/PIRCaseAudit';
import content from './Content/ReviewRequest.json';
//error handling
import InfoBox from '../../components/ServiceUI/Core/InfoBox/InfoBox';
import { ReviewARequest } from '../../components/ServiceUI/Core/icons/Outline';
import { getRefData } from '../../helper/getRefData';
import {
  getPersonalInfoRequest,
  getAttachments,
  editRequest,
  forwardEditRequest,
  submitPIR,
  getSRData,
} from './API/eftApiService';

export const NewRequestReviewPage: React.FC = () => {
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pirInfo, setPIRInfo] = React.useState({});
  const [attachmentInfo, setAttachmentInfo] = useState([]);
  const dispatch = useDispatch();
  //variables
  const { id } = useParams();
  const navigate = useNavigate();
  const caseID = id?.slice(id.indexOf(' ') + 1, id.indexOf('!')) ?? ''; // This will be used later

  React.useEffect(() => {
    document.title = 'Review request';
  }, []);

  useEffect(() => {
    if (!caseID) return;
    fetchPersonalInfoAndAttachments(caseID);
  }, [caseID]);

  //API calls

  const fetchPersonalInfoAndAttachments = async (caseID) => {
    try {
      setShowSpinnerApiIsInProgress(true);
      const personalInfoRes = await getPersonalInfoRequest(caseID);
      const pegaRes = personalInfoRes.data.content.PersonalInfoRequest;
      const [serviceType, applicantType] = await Promise.all([
        getRefData('PIR', 'PIRType', pegaRes.ServiceType),
        getRefData('PIR', 'PIRApplicantType', pegaRes.ApplicantType),
      ]);
      setPIRInfo({ ...pegaRes, ServiceType: serviceType, ApplicantType: applicantType });
      const attachmentsRes = await getAttachments(caseID);
      if (attachmentsRes.data.attachments) {
        const finalArr = attachmentsRes?.data?.attachments.filter(
          (file) =>
            file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE &&
            file.category !== appConstants.ATTACHMENTS_FILE_CATEGORY,
        );
        setAttachmentInfo(finalArr);
      }
    } catch (error) {
      // @ts-ignore
      dispatch(setShowError({ error: true, code: error.response?.status || '', errorObj: error.response }));
    } finally {
      setShowSpinnerApiIsInProgress(false);
    }
  };

  const editRequestHandler = async () => {
    try {
      await editRequest(caseID);
      if (id) {
        try {
          await forwardEditRequest(id, caseID);
          const path = `/personal-information-request/new/${caseID}`;
          navigate(path);
        } catch (error) {
          // @ts-ignore
          dispatch(setShowError({ hasError: true, error }));
        }
      }
    } catch (error) {
      // @ts-ignore
      dispatch(setShowError({ hasError: true, error }));
    }
  };

  //Call to update status after user submits the request
  const submitPIRHandler = async () => {
    try {
      setIsSubmitting(true);
      await submitPIR(id);
      const res = await getSRData(caseID);

      if (res.data.content?.pyErrorCode) {
        throw new Error('Error code received from server');
      }

      navigate(`/personal-information-request/complete/${caseID.split(' ')[1]}`, {
        state: [res.data],
      });

      await PIRCaseAudit(caseID, '', 'PIR01');
    } catch (error) {
      dispatch(setShowError({ hasError: true, error }));
    } finally {
      setIsSubmitting(false);
      setShowSpinnerApiIsInProgress(false);
    }
  };

  return (
    <>
      <Header {...content.header} />
      <SubContainer verticalMargin="1rem">
        <InfoBox>
          <ReviewARequest height="3rem" />
          <Heading mt="1rem" as="h2" fontSize="md" fontWeight="medium">
            {content.infobox.heading}
          </Heading>
          <Text fontWeight="light">{content.infobox.content}</Text>
          <Button mt="1rem" variant="secondary_white" onClick={() => editRequestHandler()}>
            Edit Request
          </Button>
        </InfoBox>
        <>{showSpinnerApiIsInProgress ? <Spinner /> : null}</>
        <ViewPIR PIR={pirInfo} Attachments={attachmentInfo} />

        <Flex justifyContent="space-between" flexDir={{ lg: 'row', base: 'column' }} gap={3} mb="3rem">
          <Flex gap={3} flexDir={{ lg: 'row', base: 'column' }}>
            <Button variant="tertiary" onClick={() => navigate('/personal-information-request')}>
              Cancel
            </Button>
          </Flex>
          <Button variant="primary" onClick={submitPIRHandler} isLoading={isSubmitting} loadingText="Submitting...">
            Submit
          </Button>
        </Flex>
      </SubContainer>
    </>
  );
};
