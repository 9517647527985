import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { PegaCaseBadge } from '../../Core/Badge/Badge';
import InfoBox from '../../Core/InfoBox/InfoBox';
import { Success } from '../../Core/icons/Outline';
import sanitizeHtml from 'sanitize-html';
import { Tooltip } from '../../../ServiceUI/Core/Tooltip/Tooltip';
import { useLocation } from 'react-router-dom';

interface SubmissionSuccessProps {
  mainHeading: string;
  subHeading: string;
  mainContent: string;
  dateHeading: string;
  caseID: string;
  requestTypeID: string;
  requestName?: string;
  date: Date;
  status: string;
  tooltipContent?: string;
}

export const SubmissionSuccess: React.FC<SubmissionSuccessProps> = (props: SubmissionSuccessProps): JSX.Element => {
  const location = useLocation();
  const [caseDetails, setCaseDetails] = useState(location.state[0]);

  return (
    <Box py="1.5rem">
      <Box height={{ lg: '4rem', base: '3rem' }}>
        <Success height="100%" />
      </Box>
      <Heading as="h2" fontSize={{ lg: 'xl', base: 'sm' }} fontWeight="medium" mt="1rem" mb="2rem">
        {props.mainHeading}
      </Heading>
      <InfoBox>
        <Heading as="h3" fontSize="sm" fontWeight="medium">
          {props.subHeading}
        </Heading>
        <Box
          fontWeight="light"
          data-testid="description"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(props.mainContent),
          }}
        />
        <Box>
          <Text fontWeight="medium" mb="0.5rem">
            Request information
          </Text>
        </Box>
        <Flex justifyContent="space-between" gap={{ lg: 3, base: 1 }} flexDir={{ lg: 'row', base: 'column' }}>
          <Flex gap={{ lg: 7, base: 1 }} flexDir={{ lg: 'row', base: 'column' }}>
            {/* Display Request Name only on SR */}
            <Box>
              <Text m="0">Request Name</Text>
              <Text fontWeight="light" m="0">
                {props.requestName}
              </Text>
            </Box>
            {/* Display request type ID */}
            <Box>
              <Flex gap={1} alignItems="center">
                <Text m="0">{props.requestTypeID}</Text>
                {props.tooltipContent && <Tooltip content={props.tooltipContent} placement="bottom" />}
              </Flex>
              <Text fontWeight="light" m="0">
                {props.caseID.substring(props.caseID.indexOf('P'))}
              </Text>
            </Box>
            <Box>
              <Text m="0">Date {props.dateHeading}</Text>
              <Text fontWeight="light" m="0">
                {new Date(props.date).toDateString()}
              </Text>
            </Box>
            {/* Display for SR GEMS */}
            {caseDetails?.content?.GemsNotificationID && (
              <Box>
                <Flex gap={1} alignItems="center">
                  <Text m="0" fontSize={{ lg: 'xl', base: 'sm' }}>
                    Service Request Number
                  </Text>
                  {props.tooltipContent && <Tooltip content={props.tooltipContent} placement="bottom" />}
                </Flex>
                <Text fontWeight="light" m="0">
                  {caseDetails?.content?.GemsNotificationID}
                </Text>
              </Box>
            )}
          </Flex>
          <Box>
            <Text m="0">Current status</Text>
            <PegaCaseBadge status={props.status} />
          </Box>
        </Flex>
      </InfoBox>
    </Box>
  );
};
