import { appConstants } from '../../../helper/client/constant';

//NEW ONES
// Function to format time
export const formatTime = (timeString: string) => {
  if (!timeString) return null;
  return timeString.split('.')[0]; // Remove the milliseconds and timezone part
};

// Function to format date
export const formatDate = (dateString: string) => {
  if (!dateString) return null;
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`; // Reformat to DD-MM-YYYY
};

//View Page - get selected group/entity field for Primary Contact
export const getSelectedGroupDiv = (refDataDropdown, selectedCode) => {
  const selecteGroupDataDescription = refDataDropdown.filter((item) => item.code === selectedCode)[0].description;
  return selecteGroupDataDescription;
};

//Determine clone SR Create page URL
export const navigatetoCreatePage = (clonedCaseData) => {
  const RequestType =
    clonedCaseData.ServiceType === appConstants.SERVICE_REQUEST_TYPE
      ? appConstants.SERVICE_REQUEST_TYPE_MINOR
      : appConstants.SERVICE_REQUEST_TYPE_FULL;
  const path = `/my-service-request/edit-service-request-create/${clonedCaseData.CloneSRID}?type=${RequestType}`;
  window.location.href = path;
};

// NEED to figure out which ones are SR specific vs. EFT
// TO REFACTOR FOR SR
export const getPageInstructionObj = (updatedItemArr, currValue = '', fieldID = '', reference = '') => {
  return new Promise((resolve, reject) => {
    //Prepare for Page Instructions for array
    let preparePageInstructionsArr = [] as Array<any>;
    preparePageInstructionsArr = updatedItemArr.map((item) => {
      return {
        instruction: 'UPDATE',
        target: '.' + item.reference.replace('.' + item.fieldID, ''),
        content: {
          [item.fieldID]: item.value,
        },
      };
    });
    resolve([...preparePageInstructionsArr]);
  });
};

export const checkValueStoredInRedux = (value: boolean | any) => {
  if (typeof value === 'boolean') {
    return true;
  } else {
    return value.length > 0;
  }
};

export const checkifItHasArray = (valueObj, array) => {
  if (Array.isArray(valueObj)) {
    valueObj.forEach((item) => {
      checkifItHasArray(item, array);
    });
  } else {
    array.push(valueObj);
  }
};

export const getReduxData = (fieldGroupList) => {
  let requestInfoData = [];
  for (const [key, value] of fieldGroupList) {
    checkifItHasArray(value, requestInfoData);
  }
  const filterDataRefField = requestInfoData.filter((item: any) => item.reference && item.value !== undefined);
  const filteredArray = filterDataRefField.filter((item: any) => checkValueStoredInRedux(item.value));
  const reqDetailsAllFields = [...filteredArray];
  return reqDetailsAllFields;
};

//Need to store Location, primary, secondary, isPrimarySelcted, extraFieldsForPageInstrction to redux to use function
export const prepareFormFields = (reduxStoreFields) => {
  // const DeliveryDataFieldData =
  //   reduxStoreFields.DeliveryDetailsData && reduxStoreFields.DeliveryDetailsData[0]?.field
  //     ? reduxStoreFields.DeliveryDetailsData[0]?.field
  //     : [];
  // const DeliveryDataList = [['DeliveryDetailsData', DeliveryDataFieldData]];
  // const deliveryData = getReduxData(DeliveryDataList);
  //const requestDetailsDatafromStore = getReduxData(reduxStoreFields);
  //NEED to define pageInstructions for data PUT request
  // const allUpdatedValue = [
  //   ...getUpdatedValueForLocationBasedOnTypeSelection,
  //   ...locationData,
  //   ...primaryPersonalData,
  //   ...secondaryPersonalData,
  //   ...requestDetailsDatafromStore,
  //   ...onlyOperatorIsPrimaryDetails,
  //   ...hasSecondarySelected,
  //   ...extraFieldsForPageInstrction,
  // ];
  //return allUpdatedValue;
};

export const flattenData = (data) => {
  if (data) {
    return data
      .reduce((acc, curr) => {
        acc.push(...Object.values(curr));
        return acc;
      }, [])
      .flat();
  } else {
    return [];
  }
};

export const getSelectedDescription = (refDataDropdown, selectedCode) => {
  const selectedRefDataDescription = refDataDropdown?.filter((item) => item?.code === selectedCode)[0]?.description;
  return selectedRefDataDescription;
};
