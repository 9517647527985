import { Button, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { SubmissionSuccess } from '../../components/ServiceUI/ServiceRequest/SubmissionSuccess/SubmissionSuccess';
import content from './Content/Complete.json';
import { appConstants } from '../../helper/client/constant';

export const SRNewRequestCompletePage: React.FC = () => {
  React.useEffect(() => {
    document.title = 'Request completed';
  }, []);
  const { id } = useParams();
  const location = useLocation();
  const [caseDetails] = useState(location.state[0]); //for GEMS SR number

  return (
    <>
      <Header
        {...content.header}
        mainHeading={
          caseDetails.content.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE
            ? appConstants.TITLE_REQUEST_TYPE_MAINTENANCE
            : appConstants.TITLE_REQUEST_TYPE_NOT_MAINTENANCE
        }
        breadcrumbItems={[{ name: 'Service Request', url: '/my-service-request' }]}
      />
      <SubContainer verticalMargin="1rem">
        <SubmissionSuccess
          {...content.submissionSuccess}
          dateHeading="Requested"
          caseID={`${id}`}
          requestTypeID="Service Connect ID"
          requestName={caseDetails?.content?.ServiceRequest.RequestTitle}
          date={new Date()}
          status="pending-fulfillment"
        />
        <Flex justifyContent="flex-end">
          <Button variant="primary" w={{ lg: 'auto', base: '100%' }} mb="3rem" as={Link} to="..">
            Return to home
          </Button>
        </Flex>
      </SubContainer>
    </>
  );
};
