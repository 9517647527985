import { Button, FormControl, FormErrorMessage, Input, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { allEndUserFiles } from '../../../../helper/allEndUserFiles';
import { appConstants } from '../../../../helper/client/constant';
import convertSpecialCharacters from '../../../../helper/convertSpecialChars';
import { request } from '../../../../helper/useAxios';
import { PIRCaseAudit } from '../../../../pages/EFT/API/PIRCaseAudit';
import { EFTConstants } from '../../../../pages/EFT/EFTConstant';
import { setFieldValue } from '../../../../reducers/eftRequestDetailFormReducer';
import { setShowError } from '../../../../reducers/errorHandling';
import DocumentUploadSecurityNotice from '../../EFT/Content/DocumentUploadSecurityNotice';
import { FileList } from '../../Core/FileList/FileList';
import { SCForm } from '../FormElements';
import UtilityModals from '../Modal/UtilityModals';
import { Spinner } from '../Spinner/Spinner';
import { getUploadInfo } from './getUploadInfo';
import { removeFileExtension } from '../../../../helper/removeFileExtension';

interface FormInputFileUploadProps {
  caseIDOriginal: string;
  label: string;
  required?: boolean;
  reference: string;
  formData: any;
  user?: string;
  attachmentCategory: string;
  fieldPropMapping?: object;
}

const FormInputFileUpload = ({
  caseIDOriginal,
  label,
  required,
  reference,
  formData,
  user,
  attachmentCategory,
  fieldPropMapping,
}: FormInputFileUploadProps) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [spinner, setSpinner] = useState<{ active: boolean; overlay?: boolean }>({ active: false, overlay: false });
  const dispatch = useDispatch();
  const { confirm } = UtilityModals();

  const [uploadErrors, setUploadErrors] = useState({
    isInvalid: false,
    fileInfo: [],
  });
  const [addedFile, setAddedFile] = useState<any>({ selectedFile: [] });
  const [addingFiles, setAddingFiles] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({
    selectedFile: [],
  });
  const uploadInfo = getUploadInfo(attachmentCategory);
  const validFileTypes =
    user === 'businessUser'
      ? EFTConstants.PIR_ALLOWED_FILE_EXT_BUSINESS_USER
      : EFTConstants.PIR_ALLOWED_FILE_EXT_END_USER;
  const auditcode = user === 'businessUser' ? EFTConstants.AUDITCODE_BUSINESS_USER : EFTConstants.AUDITCODE_END_USER;
  const singleFileSizeLimit =
    user === 'businessUser' ? EFTConstants.IAU_SINGLE_FILE_SIZE_LIMIT : EFTConstants.SINGLE_FILE_SIZE_LIMIT;
  const totalFilesSizeLimit =
    user === 'businessUser' ? EFTConstants.IAU_TOTAL_FILE_SIZE_LIMIT : EFTConstants.TOTAL_FILE_SIZE_LIMIT;
  const singleFileSizeLimitError =
    user === 'businessUser' ? EFTConstants.IAU_SINGLE_FILE_SIZE_LIMIT_ERROR : EFTConstants.SINGLE_FILE_SIZE_LIMIT_ERROR;
  const totalFilesSizeLimitError =
    user === 'businessUser' ? EFTConstants.IAU_TOTAL_FILE_SIZE_LIMIT_ERROR : EFTConstants.TOTAL_FILE_SIZE_LIMIT_ERROR;
  const [allFiles, setAllFiles] = useState<any>([]);
  //@ts-ignore
  const fileUploadList = uploadedFile.selectedFile.map((file) => file?.name).join(', ');

  useEffect(() => {
    getAllSavedFiles();
  }, []);

  useEffect(() => {
    setValue(attachmentCategory, fileUploadList, { shouldValidate: true });
  }, [uploadedFile]);

  const deleteAttachment = (file) => {
    setSpinner({ active: true, overlay: true });
    setAllFiles(allFiles.filter((item: any) => item.name === file.name));
    const fileFoundinSavedArray = uploadedFile.selectedFile.filter((obj: any) => obj.name === file.name);
    //This runs when deleting on a saved request
    if (fileFoundinSavedArray.length > 0) {
      request(appConstants.API_ATTACHMENTS_FILE, 'delete', { id: file.attachmentLinkID })
        .then(() => {
          setSpinner({ active: false });
          getAllSavedFiles();
          const el = document.getElementById(`${EFTConstants.PIR_FILE_UPLOAD_ID}-${reference}`) as HTMLInputElement;
          if (el) {
            el.value = '';
          }
        })
        .catch((error) => {
          dispatch(setShowError({ hasError: true, error }));
        });
    }
  };
  const clearAttachments = () => {
    setAddedFile((prevState) => {
      return {
        ...prevState,
        selectedFile: [],
      };
    });
    const el = document.getElementById(`${EFTConstants.PIR_FILE_UPLOAD_ID}-${attachmentCategory}`) as HTMLInputElement;
    if (el) {
      el.value = '';
    }
  };
  const getUpdatedFilesFromPega = (responseFromPega) => {
    const filteredArrayNonCorrespondese = responseFromPega.attachments.filter(
      (file) => file.category === attachmentCategory,
    );
    filteredArrayNonCorrespondese.forEach((element) => {
      caseAuditForEachFile(element);
    });

    const fileInfoArray = filteredArrayNonCorrespondese.map((file) => {
      return {
        name: file.fileName,
        attachmentLinkID: file.ID,
        size: file.fileSizeinbytes,
      };
    });

    dispatch(
      setFieldValue({
        reference,
        value: fileInfoArray.length > 0 ? 'contains file' : '',
        formData,
        required: false,
        visible: true,
        error: fileInfoArray.length === 0 ? true : false,
      }),
    );

    setUploadedFile((prevState: any) => {
      return {
        ...prevState,
        selectedFile: [...fileInfoArray],
      };
    });
  };
  const getAllSavedFiles = () => {
    setSpinner({ active: true, overlay: false });
    //This is used when the user is continuing a saved request
    const url = appConstants.API_CASES + caseIDOriginal + '/attachments';
    request(url, 'get')
      .then((res) => {
        setSpinner({ active: false });
        if (res.data.attachments) {
          getUpdatedFilesFromPega(res.data);
        } else {
          const noFiles = [];
          dispatch(
            setFieldValue({
              reference,
              value: noFiles.length > 0 ? 'contains file' : '',
              formData,
              required: false,
              visible: true,
              error: noFiles.length === 0 ? true : false,
            }),
          );
          setUploadedFile((prevState) => {
            return {
              ...prevState,
              selectedFile: [],
            };
          });
        }
        setAddedFile((prevState) => {
          return {
            ...prevState,
            selectedFile: [],
          };
        });
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };
  const caseAuditForEachFile = (file) => {
    PIRCaseAudit(caseIDOriginal, file.ID, auditcode)
      .then((_) => {})
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };
  const apiCallsForUpload = (data, caseId, fileInfo) => {
    return new Promise((resolve, reject) => {
      request(appConstants.API_ATTACHMENT_UPLOAD, 'post', { data })
        .then((res) => {
          const data = {
            attachments: [
              {
                type: appConstants.SR_ATTACHMENT_TYPE,
                category: attachmentCategory,
                name: removeFileExtension(fileInfo.name),
                ID: res.data.ID,
              },
            ],
          };
          request(appConstants.API_CASES + caseId + '/attachments', 'post', { data })
            .then(() => {
              getAllSavedFiles();
              resolve('all done');
            })
            .catch((error) => {
              dispatch(setShowError({ hasError: true, error }));
            });
          setSpinner({ active: false });
        })
        .catch((error) => {
          dispatch(setShowError({ hasError: true, error }));
          setSpinner({ active: false });
        });
    });
  };
  const resetErrorArray = () => {
    setUploadErrors({
      isInvalid: false,
      fileInfo: [],
    });
  };
  const addError = (fileName: string, errorMessage: string) => {
    setUploadErrors((prevState: any) => {
      return {
        ...prevState,
        isInvalid: true,
        fileInfo: [
          {
            fileName,
            errorMessage,
          },
        ],
      };
    });
  };

  function meetsMinimumFiles(files: String): boolean {
    return uploadInfo.minFiles ? files.split(',').length >= uploadInfo.minFiles : true;
  }

  function exceedsMaximumFiles(files: String): boolean {
    return uploadInfo.maxFiles ? files.split(',').length <= uploadInfo.maxFiles : true;
  }

  const checkFileValidity = async (files: File[]) => {
    setAddingFiles(true);
    const allUserFiles = await allEndUserFiles(caseIDOriginal);
    const totalFileSizeUploadedToPIR = allUserFiles.reduce((n: any, { size }) => n + Number(size), 0);
    const totalFileSizeNotUploadedToPega = addedFile.selectedFile.reduce((n: any, { size }) => n + Number(size), 0);

    //Checking that files uploaded does not exceed maximum - if so only upload the first files up to the limit
    if (uploadInfo.maxFiles !== 0 && files.length > uploadInfo.maxFiles) {
      files = [...files.slice(0, uploadInfo.maxFiles)];
    }
    // Check that files uploaded meet minmum requiremnet
    if (uploadInfo.minFiles && files.length < uploadInfo.minFiles) {
      files = [...files.slice(0, uploadInfo.minFiles)];
    }
    let addedFilesSize = 0;
    for (let index = 0; index < files.length; index++) {
      let fileToAdd = files[index];

      addedFilesSize = addedFilesSize + fileToAdd.size;

      if (
        fileToAdd.size <= singleFileSizeLimit &&
        addedFilesSize + totalFileSizeUploadedToPIR + totalFileSizeNotUploadedToPega <= totalFilesSizeLimit
      ) {
        if (validFileTypes.includes(fileToAdd.type)) {
          setAllFiles([...allFiles, fileToAdd]);
          setAddedFile((prevState: any) => {
            const newArray = [...prevState.selectedFile, files[index]];
            return {
              ...prevState,
              selectedFile: newArray,
            };
          });
        } else {
          addError(fileToAdd.name, EFTConstants.PIR_END_USER_FILE_TYPE_ERROR);
        }
      } else {
        if (addedFilesSize + totalFileSizeUploadedToPIR + totalFileSizeNotUploadedToPega >= totalFilesSizeLimit) {
          addError(fileToAdd.name, totalFilesSizeLimitError);
        } else {
          addError(fileToAdd.name, singleFileSizeLimitError);
        }
      }
      setAddingFiles(false);
    }
  };
  const onFileChange = async (event) => {
    //Clearing errors
    resetErrorArray();

    //Getting array of files from the event
    let filesToUpload: File[] = Object.values(event.target.files);
    //Sending the file array to the validation checker/uploader
    checkFileValidity(filesToUpload);
  };
  const onFileUpload = async (event) => {
    // If business user show security notice modal
    if (user === 'businessUser') {
      const confirmed = await confirm(<DocumentUploadSecurityNotice />, {
        okText: 'Continue',
        cancelText: 'Back',
        modalProps: { size: 'large', closeOnOverlayClick: true },
      });
      // If user clicks back button return from function
      if (!confirmed) {
        return;
      }
    }
    setSpinner({ active: true, overlay: true });
    const totalFiles = addedFile.selectedFile.length + uploadedFile.selectedFile.length;

    if (totalFiles <= uploadInfo.maxFiles || uploadInfo.maxFiles === 0) {
      // Create an object of formData
      const bodyFormData = new FormData();
      for (let item = 0; item < addedFile.selectedFile.length; item++) {
        bodyFormData.delete(appConstants.SR_ATTACHMENT_FORM_DATA_KEY);
        bodyFormData.append(
          appConstants.SR_ATTACHMENT_FORM_DATA_KEY,
          addedFile.selectedFile[item],
          addedFile.selectedFile[item].name,
        );
        await apiCallsForUpload(bodyFormData, caseIDOriginal, addedFile.selectedFile[item]);
      }
    } else {
      addError('', EFTConstants.PIR_END_USER_TOTAL_FILE_AMOUNT_ERROR);
    }
    const el = document.getElementById(`${EFTConstants.PIR_FILE_UPLOAD_ID}-${attachmentCategory}`) as HTMLInputElement;
    if (el) {
      el.value = '';
    }
    setSpinner({ active: false });
  };
  return (
    <>
      <SCForm.FilePicker
        tooltipContent={uploadInfo.tooltipContent}
        reference={reference}
        categoryId={attachmentCategory}
        label={convertSpecialCharacters(label)}
        content={uploadInfo.caption}
        onClick={onFileChange}
        required={Boolean(required)}
        multiple={uploadInfo.maxFiles !== 1}
        loading={addingFiles}
        loadingText="Adding attachments"
        ctaText="Add attachment"
        desktopWidth={12}
        // @ts-ignore
        validation={fieldPropMapping?.[attachmentCategory]?.validation}
        disabled={
          uploadInfo.maxFiles !== 0
            ? addedFile.selectedFile.length >= uploadInfo.maxFiles ||
              uploadedFile.selectedFile.length >= uploadInfo.maxFiles
            : false
        }
        validFileTypes={validFileTypes}
      />
      {addedFile.selectedFile.length > 0 && (
        <>
          <Button variant="primary" onClick={onFileUpload}>
            Upload
          </Button>
          <FileList
            header="List of files to be uploaded:"
            onClick={() => clearAttachments()}
            files={addedFile.selectedFile}
            variant="addedFiles"
          />
        </>
      )}
      {uploadedFile.selectedFile.length > 0 && (
        <FileList
          header="Uploaded files:"
          onClick={(item) => deleteAttachment(item)}
          files={uploadedFile.selectedFile}
          variant="uploadedFiles"
        />
      )}
      {spinner.active && <Spinner overlay={spinner.overlay} />}
      <FormControl isInvalid={!!errors?.[attachmentCategory]} mb="30px">
        <Input
          type="hidden"
          // @ts-ignore
          defaultValue={fileUploadList}
          {...register(attachmentCategory, {
            required: true,
            ...fieldPropMapping?.[attachmentCategory]?.validation,
            validate: {
              minimumFiles: (value) => meetsMinimumFiles(value) || EFTConstants.PIR_IDENTITY_TWO_FILES_MSG,
              maximumFiles: (value) => exceedsMaximumFiles(value) || EFTConstants.PIR_IDENTITY_TWO_FILES_MSG,
            },
          })}
        />
        <FormErrorMessage>
          <>{errors?.[attachmentCategory] && errors?.[attachmentCategory]?.message}</>
        </FormErrorMessage>
      </FormControl>
      {uploadErrors.isInvalid &&
        uploadErrors.fileInfo.map((item: any, index) => {
          return (
            <Text key={index} color="redalert" fontSize="xxs">
              {item.fileName && `${item.fileName}: `}
              {item.errorMessage ?? ''}
            </Text>
          );
        })}
    </>
  );
};

export default FormInputFileUpload;
