import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { ViewSR } from '../../components/ServiceUI/ServiceRequest/ViewSR/ViewSR';
import { appConstants } from '../../helper/client/constant';
import { setShowError } from '../../reducers/errorHandling';
import content from './Content/ReviewRequest.json';
import {
  getServiceRequest,
  getAttachments,
  editRequest,
  forwardEditRequest,
  submitSR,
  getWorkType,
  getSubWorkType,
} from './API/srApiService';

//error handling
import InfoBox from '../../components/ServiceUI/Core/InfoBox/InfoBox';
import { ReviewARequest } from '../../components/ServiceUI/Core/icons/Outline';
import { getRefData } from '../../helper/getRefData';
import { getSelectedDescription } from './Helper/srHelperFunc';

export const SRNewRequestReviewPage: React.FC = () => {
  //states
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [srInfo, setSRInfo] = React.useState({});
  const [assignmentID, setAssignmentID] = React.useState();
  const [attachmentInfo, setAttachmentInfo] = useState([]);
  const [workType, setWorkType] = useState('');
  const [subWorkType, setSubWorkType] = useState('');

  //variables
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  let isMinorRequestParam = '';

  //check if in new or edit state
  const isNewSR = location.state !== null; //if location state is not null then its a new SR

  //only works for new SRs not edit
  let caseID = '';
  if (isNewSR) {
    caseID = location?.state[0].caseId.caseId; //e.g.DOD-SCONN-WORK S-127161 for new SR
    isMinorRequestParam = location?.state[0]?.isMinorRequest;
  }
  const requestType = window.location.search;
  const urlParams = new URLSearchParams(requestType);
  const type = urlParams.get('type');

  //Get only id value from params
  const splitId = id?.slice(id.indexOf(' ') + 1, id.indexOf('!')) ?? '';
  const idOnly = id?.includes('ASSIGN') ? splitId : id;

  //get SR type (Minor or Full)
  const srType = isMinorRequestParam ? appConstants.SERVICE_REQUEST_TYPE_MINOR : appConstants.SERVICE_REQUEST_TYPE_FULL;
  const finalSrType = isMinorRequestParam === '' ? type : srType;

  //use effects
  React.useEffect(() => {
    if (!id) return;
    fetchSRAndAttachments(caseID);
  }, [id]);
  React.useEffect(() => {
    document.title = isMinorRequestParam ? 'Review minor maintenance request' : 'Review new service request';
  }, []);

  //API calls
  const fetchSRAndAttachments = async (caseID) => {
    try {
      setShowSpinnerApiIsInProgress(true);
      const serviceRequestRes = await getServiceRequest(caseID, idOnly);
      setAssignmentID(serviceRequestRes.data.assignments[0].ID);
      const pegaRes = serviceRequestRes.data.content.ServiceRequest;
      const [serviceType, groupDiv] = await Promise.all([
        getRefData('SR', 'servicetype', pegaRes.ServiceType),
        getRefData('SR', 'groupdiv', pegaRes.PrimaryContact.Service),
      ]);
      setSRInfo({ ...pegaRes, ServiceType: serviceType, Service: groupDiv });

      let SRType =
        serviceRequestRes.data.content.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE
          ? 'WorkType'
          : 'serviceType';

      getWorkTypeDesc(SRType, serviceRequestRes.data.content.ServiceRequest.WorkType);
      getWorkTypeDesc(SRType, serviceRequestRes.data.content.ServiceRequest.WorkType);
      if (SRType) {
        const workSubTypeCode = serviceRequestRes.data.content.ServiceRequest.WorkSubType;
        getSubWorkTypeDesc(serviceRequestRes.data.content.ServiceRequest.WorkType, workSubTypeCode);
      }
      const attachmentsRes = await getAttachments(idOnly);
      if (attachmentsRes.data.attachments) {
        const finalArr = attachmentsRes?.data?.attachments.filter(
          (file) =>
            file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE &&
            file.category !== appConstants.ATTACHMENTS_FILE_CATEGORY,
        );
        setAttachmentInfo(finalArr);
      }
    } catch (error) {
      // @ts-ignore
      dispatch(setShowError({ error: true, code: error.response?.status || '', errorObj: error.response }));
    } finally {
      setShowSpinnerApiIsInProgress(false);
    }
  };

  //Edit SR Handler
  const editRequestHandler = async () => {
    try {
      await editRequest(caseID, idOnly);
      if (id) {
        try {
          await forwardEditRequest(caseID, id, assignmentID);
          let path = '';
          //get call happens on editcreaterequest need to make this dynamic depending on type of request (new/edit)
          if (isNewSR) {
            path = `/my-service-request/edit-service-request-create/${caseID}?type=${finalSrType}`;
          } else {
            path = `/my-service-request/edit-service-request-create/${idOnly}?type=${finalSrType}`;
          }
          navigate(path);
        } catch (error) {
          // @ts-ignore
          dispatch(setShowError({ hasError: true, error }));
        }
      }
    } catch (error) {
      // @ts-ignore
      dispatch(setShowError({ hasError: true, error }));
    }
  };

  //get worktype
  const getWorkTypeDesc = (type, selectedCode) => {
    getWorkType(type)
      .then((res) => {
        let selectedDescription = 'Select';
        selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
        setWorkType(selectedDescription);
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  //get worktype description
  const getSubWorkTypeDesc = (currentworkType, selectedCode) => {
    getSubWorkType(currentworkType)
      .then((res) => {
        let selectedDescription = 'Select';
        selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
        setSubWorkType(selectedDescription);
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  //Call to update status after user submits the request
  const submitSRHandler = async () => {
    try {
      setIsSubmitting(true);
      await submitSR(assignmentID);
      const res = await getServiceRequest(caseID, idOnly);
      if (res.data.content?.pyErrorCode) {
        throw new Error('Error code received from server');
      }
      if (caseID === '' && idOnly) {
        navigate(`/my-service-request/complete/${idOnly.split(' ')[1]}`, {
          state: [res.data],
        });
      } else {
        navigate(`/my-service-request/complete/${caseID.split(' ')[1]}`, {
          state: [res.data],
        });
      }
    } catch (error) {
      dispatch(setShowError({ hasError: true, error }));
    } finally {
      setIsSubmitting(false);
      setShowSpinnerApiIsInProgress(false);
    }
  };

  return (
    <>
      <Header
        {...content.header}
        mainHeading={isMinorRequestParam ? 'Minor maintenance request' : 'New service request'}
        subHeading={isMinorRequestParam ? content.header.subHeading : 'Submit a request for base services'}
      />

      <SubContainer verticalMargin="1rem">
        <InfoBox>
          <ReviewARequest height="3rem" />
          <Heading mt="1rem" as="h2" fontSize="md" fontWeight="medium">
            {content.infobox.heading}
          </Heading>
          <Text fontWeight="light">{content.infobox.content}</Text>
          <Button mt="1rem" variant="secondary_white" onClick={() => editRequestHandler()}>
            Edit Request
          </Button>
        </InfoBox>
        <>{showSpinnerApiIsInProgress ? <Spinner /> : null}</>
        <ViewSR SR={srInfo} Attachments={attachmentInfo} srWorktype={workType} srSubWorkType={subWorkType} />

        <Flex justifyContent="space-between" flexDir={{ lg: 'row', base: 'column' }} gap={3} mb="3rem">
          <Flex gap={3} flexDir={{ lg: 'row', base: 'column' }}>
            <Button variant="secondary_white" onClick={() => navigate('/my-service-request')}>
              Back
            </Button>
          </Flex>
          <Button variant="primary" onClick={submitSRHandler} isLoading={isSubmitting} loadingText="Submitting...">
            Submit
          </Button>
        </Flex>
      </SubContainer>
    </>
  );
};
