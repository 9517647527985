// API service file - TO REFACTOR FOR SR
import { appConstants } from '../../../helper/client/constant';
import { request } from '../../../helper/useAxios';

const buildURL = (baseUrl, id, path = '') => `${baseUrl}${id}${path}`;

// Get Service Requests
export const getServiceRequest = (caseID, idOnly) => {
  let URL = '';
  if (caseID === '') {
    URL = appConstants.API_CASES + idOnly;
  } else {
    URL = appConstants.API_CASES + caseID;
  }
  return request(URL, 'get');
};

// Get Attachments
export const getAttachments = (idOnly) => {
  const URL = appConstants.API_CASES + idOnly + '/attachments';
  return request(URL, 'get');
};

// Edit Request
export const editRequest = (caseID, idOnly) => {
  let URL = '';
  if (caseID === '') {
    URL = appConstants.API_EDIT_CASE + idOnly;
  } else {
    URL = appConstants.API_EDIT_CASE + caseID;
  }
  return request(URL, 'put');
};

// Forward Request
export const forwardEditRequest = (caseID, id, assignmentID) => {
  //if caseID is empty then its edit request
  let URL = '';
  if (caseID === '') {
    URL = appConstants.API_ASSIGNMENTS + assignmentID + '?actionID=ReviewRequest';
  } else {
    URL = appConstants.API_ASSIGNMENTS + id + '?actionID=ReviewRequest';
  }
  return request(URL, 'post');
};

// Save SR -  need to implement flag for save vs. save and close
export const saveSR = async (id: string, data: any) => {
  try {
    const res = await request(appConstants.API_CASES, 'get', { id });
    const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';
    const config = {
      headers: {
        'if-match': finalETagValue,
      },
      withCredentials: true,
    };
    return await request(appConstants.API_CASES, 'put', { id: id, data, config });
  } catch (err) {
    throw err;
  }
};

// Delete SR
export const deleteSR = async (id: string) => {
  const data = {
    content: {
      pyStatusWork: 'Withdrawn',
    },
    pageInstructions: [],
  };
  try {
    const res = await request(appConstants.API_CASES, 'get', { id });
    const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';
    const config = {
      headers: {
        'if-match': finalETagValue,
      },
      withCredentials: true,
    };
    return await request(appConstants.API_CASES, 'put', { id: id, data, config });
  } catch (err) {
    throw err;
  }
};

// Submit SR
export const submitSR = (assignmentID) => {
  const data = {
    content: {},
    pageInstructions: [],
  };
  const URL = buildURL(appConstants.API_ASSIGNMENTS, assignmentID, '?actionID=ReviewRequest');
  return request(URL, 'post', { data });
};

// Get WorkType SR
export const getWorkType = (value) => {
  const URL = appConstants.API_REF_DATA + value;
  return request(URL, 'get');
};

// //Get SubWorkType SR
export const getSubWorkType = (currentworkType) => {
  const URL = appConstants.API_WORK_TYPE + `?worktype=${currentworkType}`;
  return request(URL, 'get');
};
