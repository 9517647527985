import { Button, Link, Table, TableCaption, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import React from 'react';
import { convertBytesToMB } from '../../../../helper/convertBytesToMB';
import { PegaFile } from '../../EFT/types';

export interface FileListProps {
  header: string;
  files: File[] | PegaFile[];
  onClick(e): void;
  variant?: string;
}

export const FileList: React.FC<FileListProps> = (props: FileListProps): JSX.Element => {
  return (
    <TableContainer w={{ lg: '80%', base: '100%' }} mb="1rem">
      <Table variant="File">
        <TableCaption placement="top" textAlign="left">
          {props.header}
        </TableCaption>
        <Tbody>
          {props.files?.map((file, index) => (
            <Tr key={file.name + index}>
              <Td whiteSpace="normal">
                {file.name} | {convertBytesToMB(file.size || file.fileSizeinbytes).toFixed(2)}mb
              </Td>
              <Link hidden id="download-attachment" />
              {props.variant === 'uploadedFiles' && (
                <Td textAlign="right">
                  <Button variant="tertiary" onClick={() => props.onClick(file)}>
                    Delete
                  </Button>
                </Td>
              )}
              {props.variant === 'downloadFiles' && (
                <Td textAlign="right">
                  <Button variant="tertiary" onClick={() => props.onClick(file)}>
                    Download
                  </Button>
                </Td>
              )}
            </Tr>
          ))}
          {props.variant === 'addedFiles' && (
            <Tr>
              <Td>
                <Button variant="tertiary" onClick={() => props.onClick('all')}>
                  Clear all
                </Button>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
