//Use this file to determine the order, label and visibility of the fields on the view page

export const KeyToLabelMap = {
  //Main information
  MainInformation: {
    Label: 'Request information',

    ServiceType: 'What type of service do you require?',
    //ServiceTypeDesc: 'What type of service do you require?',
    WorkType: 'What type of service do you require?',
    WorkSubType: 'What do you need help with?',

    RequestTitle: 'Request Title',
    RequestRelatesTo: 'Request Relates to',
    RequestRelationDetail: 'Request Relates to description',
    AmountOfAssets: 'Amount of assets',
    AssetNumber: 'Asset number (if one exists or is known)',
    CostCodeCentre: 'Cost Centre Code',
    pyDescription: 'Please describe the issue',
  },

  //Request Detail information
  RequestDetail: {
    //Plumbing
    IssueDescription: 'Classify the issue',
    LeakDescription: 'How extensive is the leak?',

    //Electrician
    LightingType: 'What type of lighting is it?',
    LightingAssoc: 'Is the issue associated with?',
    IsFlickering: 'Is the light flickering?',
    IsOut: 'Is the light completely out?',
    IsSparking: 'Is the light sparking or crackling?',
    IsExtDamaged: 'Is the light externally damaged?',
    ApplType: 'What type of appliance?',
    ApplReq: 'Does the appliance require?',
    AirconType: 'What type of air conditioning unit is it?',
    AirAltSourceYN: 'Do you have another source of ventilation?',

    //Fire
    BlanketRequirement: 'What is required for the fire blanket?', //need to edit display name
    IsBrokenOrBeepingYN: 'Is the detector constantly beeping or visibly broken?',
    IsAlarmIsolatedYN: 'Is the alarm isolated and requires reset?',
    IsLeakingYN: 'Is the hydrant leaking water?',
    IsObstructingYN: 'Is the Hydrant obstructing roads or pathways?',
    LeakingLevel: 'Severity of leak',

    //Alarm
    AlarmType: 'Type of alarm',
    AudibleState: 'Is the issue',
    AccessType: 'Type of access',
    DoorType: 'Type of door',
    CanBeOpenedYN: 'Can the door open and close?',
    SafeClass: 'Safe class',

    //General Maintenace
    ServiceRequired: 'Service required',
    ServiceRelatesTo: 'Is this regarding', //need to edit display name
    ItemList: 'ItemList',
    DoesImpactSecurityYN: 'Does the issue impact security?',
    ComplianceTestingTaggingYN: 'Is this a compliance, testing or tagging issue?',
    ExtraEquipmentReqYN: 'Will extra equipment likely be required?',
    HasNoise: 'Noise',
    HasSmoke: 'Smoke',
    HasVisibleDamage: 'Visible Damage',
    ServiceReq: 'Service required',

    //Transport
    TransportLabel: 'Transport Request', //sub label
    TransportType: 'I require:',
    PickUpDate: 'Pick Up Date',
    PickUpDay: 'Pick Up Day',
    PickUpTime: 'Pick Up Time',
    DropoffLocation: 'Drop Off Location (base, building number, address etc.)',
    ReturnPickupLocation: 'Pick up location (base, building number, address etc.)',
    IsReturnLegRequired: 'Return Leg Required', //needs to be conditional
    ReturnDate: 'Return Date',
    ReturnDay: 'Return Day',
    ReturnTime: 'Drop off time',
    ReturnPickUpTime: 'Return Pick Up Time',
    VehicleOptions: 'Vehicle options (eg trailer)',
    VehicleType: 'Vehicle required',
    PickupLocation: 'Pick up location (base, building number, address etc.)',
    ReturnDropOffLocation: 'Drop Off Location (base, building number, address etc.)',
    PassengerNumber: 'Number of passengers',
    AutoOrManual: 'Automatic or manual',
    AdditionalInfo: 'Additional information (details of baggage and/or equipment)',

    //Hospitality and Catering
    HCLabel: 'Meal Details', //sub label
    Meals: 'Meal Details',
    MealType: 'Meal',
    DateReqFrom: 'Date required (from)',
    DateReqTo: 'Date required (to)',
    TimeReq: 'Time required',
    FurtherRequirements: 'Further Requirements',
    NumberAttending: 'Number Attending',
    Quantity: 'Quantity',
    PickupDelivery: 'Pickup or delivery',
    KnownAllergiesYN: 'Known allergies, intolerances, cultural/religious needs?',
    AllergyDetails: 'Provide details',

    //Waste Request
    WasteLabel: 'Waste Request', //sub label
    IsClassifiedYN: 'Is the waste Classified?',
    IsHazardousYN: 'Is the waste Hazardous?',
    IsExplosiveOrdYN: 'Is the waste Explosive Ordnance (EO)?',
    EODetails: 'Details of explosive ordnance',
    EOAcknowledge:
      'I acknowledge that EO waste must be inspected and certified as being Free From Energetic (FFE) material prior to removal from the Defence estate.',

    //display only for other transport + waste
    RDpyDescription: 'Describe the service required',
  },

  //Location information
  Location: {
    Label: 'Location information',
    State: 'State',
    Type: 'Location Type',
    Name: 'Name',
    FullAddress: 'Base Address',
    SiteOrWharf: 'Vessel Name',
    Area: 'Impacted area',
    OperatorIsPrimary: 'Are you the primary contact for this request?', // this value changes to Y/N
  },

  //Personal information
  PrimaryContact: {
    Label: 'Personal information',
    FullName: 'Full name',
    EmployeeID: 'Employee ID', //test this
    Service: 'Group or entity',
    Email: 'Email',
    Phone: 'Preferred contact number',
    PreferredContactTimes: 'How would you like to be contacted?',
  },

  //Secondary Contact information
  Contacts: {
    Label: 'Alternate point of contact',
    FullName: 'Full Name',
    EmployeeID: 'Employee ID',
    Service: 'Group or entity',
    Email: 'Defence email address',
    Phone: 'Preferred contact number',
    PreferredContactTimes: 'How would you like to be contacted?',
  },
};
