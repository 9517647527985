import { Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { Spinner } from '../../Core/Spinner/Spinner';
import { FileList } from '../../Core/FileList/FileList';
import { useDispatch } from 'react-redux';
import { KeyToLabelMap } from './KeyToLabelMap';
import { appConstants } from '../../../../helper/client/constant';
import { request } from '../../../../helper/useAxios';
import { setShowError } from '../../../../reducers';
import { formatDate, formatTime } from '../../../../pages/ServiceRequest/Helper/srHelperFunc';

interface ViewSRProps {
  SR?: any;
  Attachments?: File[];
  srWorktype?: string;
  srSubWorkType?: string;
}
interface SectionItems {
  Label: string;
  caption?: string;
  type?: string;
  subHeading?: string;
}
interface SectionProps {
  section: string;
  map: SectionItems;
  Label?: string;
  SR: any;
  attachments?: File[] | undefined;
  srWorktype?: string;
  srSubWorkType?: string;
}

interface TitleValue {
  title: string;
  value: string | [{ Address?: string; Number?: string }];
}
const PropValuePair: React.FC<TitleValue> = (props: TitleValue) => {
  const value = typeof props.value === 'object' ? Object.values(props.value[0])[0] : props.value;
  return value ? (
    <GridItem colSpan={{ lg: 1, base: 2 }}>
      <Text m={0} color="charcoal">
        {props.title}
      </Text>
      <Text fontWeight="light">{value}</Text>
    </GridItem>
  ) : null;
};

//For each sections layout
const Section: React.FC<SectionProps> = (props: SectionProps): JSX.Element => {
  const dispatch = useDispatch();
  const items = { ...props.map };
  const serviceType = props.SR?.ServiceType; // Extract the ServiceType value from the SR object
  const workType = props.SR?.WorkType;

  // Map for ServiceType to sublabel
  const subLabelMap = {
    Transport: 'TransportLabel',
    Catering: 'HCLabel',
    'Waste services': 'WasteLabel',
  };
  const subLabel = subLabelMap[serviceType || workType];

  //get the value from nested objects + arrays
  const getValue = (sectionData: any, key: string, mainData: any): any => {
    if (sectionData?.[key]) return sectionData[key]; // Check if the key exists directly in the current section
    for (const field in sectionData) {
      const nested = sectionData[field];
      if (typeof nested === 'object') {
        if (nested?.[key]) return nested[key];

        // check each object in the array (for HC)
        if (Array.isArray(nested)) {
          for (const item of nested) {
            if (item?.[key]) return item[key];
          }
        }
      }
    }
    return mainData?.[key] || null; //Check if value exists in first level
  };

  //download file logic
  const downloadFiles = async (item) => {
    try {
      const res = await request(appConstants.API_ATTACHMENTS_FILE, 'get', { id: item.ID });
      const el = document.getElementById('download-attachment') as HTMLAnchorElement;
      el.href = 'data:' + item.mimeType + ';base64,' + res.data;
      el.download = item.fileName;
      el.click();
    } catch (error) {
      dispatch(setShowError({ hasError: true, error }));
    }
  };

  //handle formatting based on type for date/time
  const formatValue = (value: any) => {
    if (typeof value === 'string') {
      if (value.includes(':')) return formatTime(value); // Format time
      if (value.includes('-') && value.length === 10) return formatDate(value); // Format date
    }
    return value;
  };

  //check if RDpyDescription should be displayed
  const shouldDisplayRDpyDescription = () => {
    const serviceType = props.SR?.ServiceType;
    const transportType = props.SR?.RequestDetail?.TransportType;
    return serviceType === 'Waste services' || (serviceType === 'Transport' && transportType === 'Other');
  };

  //find attribures for every key
  const findAttribute = (attributes: any[], key: string): any => {
    return attributes.find((attr) => attr.Name === key);
  };

  //Handle format for special cases
  const getFormattedValue = (sectionData: any, key: string, mainData: any, workType: any): any => {
    const value = getValue(sectionData, key, mainData); //get the value
    const attributes = sectionData?.Attributes || [];
    const attribute = findAttribute(attributes, key);

    //if 'IsReturnLegRequired' is false do not render this field
    if (key === 'IsReturnLegRequired' && value === 'false') {
      return null;
    }
    //convert 'OperatorIsPrimary' to 'Yes/No'
    if (key === 'OperatorIsPrimary') {
      return value ? 'Yes' : 'No';
    }
    //update text for 'Location Type'
    if (key === 'Type') {
      return value ? 'Defence Location' : 'Other Location';
    }

    if (attribute) {
      // For workType not equal to Catering, just return the DisplayValue or Value
      if (workType !== appConstants.SR_TYPE_CATERING) {
        if (attribute.DisplayValue || attribute.Value) {
          // Return only the DisplayValue or Value, not an object
          return attribute?.DisplayValue ? attribute.DisplayValue : attribute.Value;
        }
      } else {
        //If there are nested attributes get the value
        if (attribute.Attributes && attribute.Attributes.length > 0) {
          const nestedValues = attribute.Attributes.map((nestedAttribute) => {
            return nestedAttribute.DisplayValue; //get DisplayValue
          });
          return nestedValues[0]; //Return the first nested DisplayValue
        }
        return attribute.DisplayValue ? attribute.DisplayValue : attribute.Value; // If no nested attributes, return the DisplayValue or Value
      }
    }
    return formatValue(value); //format the value for time or date
  };

  return (
    <>
      {/* Render Main Label */}
      {props.section === 'Contacts' && props.SR.HasSecondaryContact === 'false' ? null : items.Label ? (
        <GridItem colSpan={2}>
          <Heading as="h3" fontSize="sm" fontWeight="medium" paddingTop="1.5rem" mb="0">
            {items.Label}
          </Heading>
        </GridItem>
      ) : null}

      {/* Render sublabel if it exists */}
      {subLabel && items[subLabel] && (
        <GridItem colSpan={2}>
          <Heading fontSize="sm" mt={4} mb={2} fontWeight="medium">
            {items[subLabel]}
          </Heading>
        </GridItem>
      )}

      {/* Display all sections - RequestDetail, Location, Primary, Secondary */}
      {Object.keys(items).map((i, index) => {
        const item = items[i];
        //check if there is a SecondaryContact section
        if (props.section === 'Contacts' && props.SR.HasSecondaryContact === 'false') {
          return null;
        }
        if (i === 'Label' || i === subLabel) return null; //skip rendering the label
        let value;
        //check RDpyDescription
        if (i === 'RDpyDescription' && shouldDisplayRDpyDescription()) {
          const rdValue =
            props.SR?.RequestDetail?.Attributes?.find((attr) => attr.Name === 'pyDescription') ||
            props.SR?.RequestDetail?.pyDescription;
          value = props.SR.ServiceType !== 'Waste services' ? (rdValue ? rdValue.Value : null) : rdValue;
        } else {
          if (typeof props.SR[props.section] === 'object') {
            value = getFormattedValue(props.SR[props.section], i, props.SR, props.SR.WorkType); // Handle nested objects using getFormattedValue function
          } else {
            // Handle string fields
            //Servicetype vs. Worktype?
            if (i === 'WorkType' && props.srWorktype) {
              value = props.srWorktype; // Display srWorktype instead of ServiceType from the API
            }
            // else if (i === 'WorkSubType' && props.srSubWorkType) {
            //   value = props.srSubWorkType; // Display srSubWorkType instead of ServiceType from the API
            // }
            else {
              // Handle other string fields normally
              value = props.SR[i];
            }
          }
        }
        return value ? <PropValuePair key={`${props.section}-${item}-${index}`} title={item} value={value} /> : null;
      })}

      {/* Display attachments */}
      {props.section === 'RequestDetail' && props.attachments && props.attachments.length > 0 && (
        <GridItem colSpan={2}>
          <FileList
            header="Uploaded files:"
            onClick={downloadFiles}
            files={props.attachments}
            variant="downloadFiles"
          />
        </GridItem>
      )}
    </>
  );
};

//The whole VIEW SR cmp
export const ViewSR: React.FC<ViewSRProps> = (props: ViewSRProps) => {
  return props.SR ? (
    <Grid gridTemplateColumns="repeat(2,1fr)" my="1rem" gap={3}>
      {/* Map all the sections */}
      {Object.keys(KeyToLabelMap).map((section) => (
        <Section
          key={section}
          section={section}
          map={KeyToLabelMap[section]}
          SR={props.SR}
          attachments={props.Attachments}
          srWorktype={props.srWorktype}
        />
      ))}
    </Grid>
  ) : (
    <Spinner />
  );
};
