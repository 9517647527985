import { Button, Flex, Grid, GridItem, Heading, Show, Spacer, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { SR, SRStatuses } from '../types';
import content from '../../../../pages/ServiceRequest/Content/LandingPage.json';
import { appConstants } from '../../../../helper/client/constant';
import { PegaCaseBadge } from '../../../ServiceUI/Core/Badge/Badge';
import { CardLink } from '../../../ServiceUI/Core/Card/CardLink';
import { CloneModal } from '../CloneRequest/CloneModal';
import { navigatetoCreatePage } from '../../../../pages/ServiceRequest/Helper/srHelperFunc';

export type SRItemProps = SR;

//All SR Statuses
const submittedType: SRStatuses = 'Pending-Fulfillment';
const draftedType: SRStatuses = 'Pending-Review';
const NewType: SRStatuses = 'New';
const completed: SRStatuses = 'Completed';
const inProgress: SRStatuses = 'In Progress';
const received: SRStatuses = 'Received';
const rejected: SRStatuses = 'Rejected';
const error: SRStatuses = 'Error';
const cancelled: SRStatuses = 'Cancelled';

export const SRItem: React.FC<SRItemProps> = (props: SRItemProps) => {
  //Clone SR states
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [selectedCaseId, setselectedCaseId] = useState('');
  const viewStatuses = [submittedType, completed, inProgress, received, rejected, error, cancelled]; //list of all statuses

  //onclick of Clone request
  const cloneRequest = (caseID) => {
    setselectedCaseId(caseID);
    setShowCloneModal(true); //display the clone modal
  };

  //onclick of Continue request
  const continueRequest = () => {
    if (viewStatuses.includes(props.pyStatusWork)) {
      window.location.href = '/my-service-request/view?CASEID=' + props.pzInsKey;
    } else {
      const RequestType =
        props.pxPages.SRIndex.ServiceType === appConstants.SERVICE_REQUEST_TYPE
          ? appConstants.SERVICE_REQUEST_TYPE_MINOR
          : appConstants.SERVICE_REQUEST_TYPE_FULL;
      //check status of SR to determine route
      if (props.pyStatusWork === NewType) {
        window.location.href = `/my-service-request/edit-service-request-create/${props.pzInsKey}?type=${RequestType}`;
      } else {
        // props.pyStatusWork is draftedType
        window.location.href = `/my-service-request/review/${props.pzInsKey}?type=${RequestType}`;
      }
    }
  };
  //Determines button text
  const draftable: Boolean = props.pyStatusWork === 'Pending-Review' || props.pyStatusWork === 'New';
  const buttonText = draftable ? 'Continue' : 'View';

  //Detemines submitted date field
  const submittedDate = props.pxPages.SRIndex.SubmissionDateTime
    ? new Date(props.pxPages.SRIndex.SubmissionDateTime).toDateString()
    : '';
  return (
    <>
      <CloneModal
        show={showCloneModal}
        {...content.cloneModal}
        onClose={() => setShowCloneModal(false)}
        caseID={selectedCaseId} // id of the case to clone
        onConfirmSuccess={navigatetoCreatePage} //determines what page to go to
      />
      <Grid
        templateColumns={{ lg: '4fr 3fr 2fr 3fr', base: 'repeat(1, 1fr)' }}
        data-testid="sr-item"
        borderRadius="0.25rem"
        bg="white"
        py="1.25rem"
        mt="0.5rem"
        gap={{ lg: '4', base: '1' }}
      >
        {/* Heading and WorkType Fields */}
        <GridItem
          colSpan={{ lg: 1, base: 4 }}
          as={Flex}
          alignItems="flex-start"
          flexWrap="wrap"
          flexDir="column"
          gap={2}
          ps="1.25rem"
        >
          {/* @ts-ignore */}
          <PegaCaseBadge status={props.pyStatusWork} />
          <Heading as="h3" m={0} fontSize="regular" fontWeight="light">
            {props.pxPages.SRIndex.RequestTitle === undefined ? 'Untitled request' : props.pxPages.SRIndex.RequestTitle}
          </Heading>
          <Text fontWeight="light" color="darkgrey">
            {props.pxPages.SRIndex.WorkType}
          </Text>
        </GridItem>

        {/* Impacted Area Field */}
        <GridItem px={{ lg: 0, base: '1.25rem' }} colSpan={1} as={Flex} alignItems="center">
          <Text m={0} fontWeight="light" color="darkgrey">
            {props.pxPages.SRIndex.Area}
          </Text>
        </GridItem>

        {/* Date Submitted Field */}
        <GridItem px={{ lg: 0, base: '1.25rem' }} colSpan={{ lg: 1, base: 4 }} as={Flex} alignItems="center">
          <Text m={0} fontWeight="light" color="darkgrey">
            <Show below="lg">Submitted: </Show>
            {submittedDate}
          </Text>
        </GridItem>

        {/* DESKTOP Card buttons */}
        <GridItem pe="1.25rem" colSpan={{ lg: 1, base: 2 }} as={Flex} justifyContent="end" alignItems="center" gap={2}>
          <Button
            display={{ lg: 'flex', base: 'none' }}
            onClick={() => cloneRequest(props.pzInsKey)}
            variant="primary"
            data-testid="clone-request"
            minWidth="70px"
            padding="1rem"
          >
            Clone
          </Button>
          <Button
            display={{ lg: 'flex', base: 'none' }}
            onClick={() => continueRequest()}
            variant="secondary"
            data-testid="action-request"
            width="100px"
            minWidth="100px"
          >
            {buttonText}
          </Button>
        </GridItem>

        {/* MOBILE Card buttons */}
        <Show below="lg">
          <GridItem
            px={{ lg: 0, base: '1.25rem' }}
            colSpan={4}
            as={Flex}
            justifyContent="end"
            alignItems="center"
            gap={2}
          >
            <CardLink text="Clone" onClick={() => cloneRequest(props.pzInsKey)} data-testid="clone-request" />
            <Spacer />
            <CardLink text={buttonText} onClick={() => continueRequest()} data-testid="action-request" />
          </GridItem>
        </Show>
      </Grid>
    </>
  );
};
