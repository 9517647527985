import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { BackLink } from '../../components/ServiceUI/Core/Links/Links';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import KeyRequestInfo from '../../components/ServiceUI/EFT/KeyRequestInfo/KeyRequestInfo';
import { ViewPIR } from '../../components/ServiceUI/EFT/ViewPIR/ViewPIR';
import { appConstants } from '../../helper/client/constant';
import { getRefData } from '../../helper/getRefData';
import { getPersonalInfoRequest, getAttachments } from './API/eftApiService';
import { setShowError } from '../../reducers/errorHandling';
import content from './Content/NewRequestView.json';

export const NewRequestViewPage: React.FC = () => {
  React.useEffect(() => {
    document.title = 'View request';
  }, []);

  let caseID = new URLSearchParams(document.location.search).get('CASEID');

  // let location = useLocation();
  // const newCaseID = location.state[0].caseId.caseId; // This will be used later

  const [pirInfo, setPirInfo] = React.useState<any>();
  const [pirRequestInfo, setPirRequestInfo] = React.useState<any>({});

  const [attachmentInfo, setAttachmentInfo] = useState([]);
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (caseID) {
      setShowSpinnerApiIsInProgress(true);
      getPersonalInfoRequest(caseID)
        .then((res) => {
          setShowSpinnerApiIsInProgress(false);
          setPirInfo(res.data);

          const pegaRes = res.data.content.PersonalInfoRequest;
          const promise1 = getRefData('PIR', 'PIRType', res.data.content.PersonalInfoRequest.ServiceType);

          const promise2 = getRefData('PIR', 'PIRApplicantType', res.data.content.PersonalInfoRequest.ApplicantType);
          Promise.all([promise1, promise2])
            .then((values) => {
              const newObj = { ...pegaRes, ServiceType: values[0], ApplicantType: values[1] };
              setPirRequestInfo(newObj);
            })
            .catch((error) => {
              dispatch(setShowError({ hasError: true, error }));
            });
        })
        .catch((error) => {
          setShowSpinnerApiIsInProgress(false);
          dispatch(setShowError({ hasError: true, error }));
        });

      getAttachments(caseID)
        .then((res) => {
          const finalArr = res.data.attachments.filter(
            (file) =>
              file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE &&
              file.category !== appConstants.ATTACHMENTS_FILE_CATEGORY,
          );
          setAttachmentInfo(finalArr);
          setShowSpinnerApiIsInProgress(false);
        })
        .catch((error) => {
          setShowSpinnerApiIsInProgress(false);
          dispatch(setShowError({ hasError: true, error }));
        });
    }
  }, []);

  return (
    <>
      <Header {...content.header} />
      <SubContainer verticalMargin="1rem">
        <BackLink text="Back to homepage" href=".." />
        <>{showSpinnerApiIsInProgress && <Spinner />}</>
        {pirInfo ? (
          <>
            <KeyRequestInfo variant="submitted" pirInfo={pirInfo} />
            <ViewPIR PIR={pirRequestInfo} Attachments={attachmentInfo} />
          </>
        ) : (
          <>No data found for this case</>
        )}
      </SubContainer>
    </>
  );
};
