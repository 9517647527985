import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { PegaCaseBadge } from '../../Core/Badge/Badge';
import { Tooltip } from '../../Core/Tooltip/Tooltip';
import content from '../Content/ViewServiceRequest.json';
import { CloneModal } from '../CloneRequest/CloneModal';
import { navigatetoCreatePage } from '../../../../pages/ServiceRequest/Helper/srHelperFunc';

const requestContent = content.keyrequestinfo;

interface KeyRequestInfoProps {
  srInfo: any;
  variant: 'submitted' | 'full';
}

const KeyRequestInfo: React.FC<KeyRequestInfoProps> = (props: KeyRequestInfoProps) => {
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [selectedCaseId, setselectedCaseId] = useState('');
  console.log(props);

  const RequestItem = (props: { title: string; value: string; tooltip?: string; width?: string }) =>
    props.value && !props.value.includes('undefined') ? (
      <Box w={{ lg: props.width ?? '30%', base: '100%' }}>
        <Flex alignItems="center" gap={2} paddingTop="1rem">
          <Text display="flex" flexDir="row" m="0">
            {props.title}
          </Text>
          {props.tooltip && <Tooltip content={props.tooltip} placement="right" />}
        </Flex>
        <Text fontWeight="light">{props.value}</Text>
      </Box>
    ) : (
      <></>
    );

  //onclick of Clone request
  const cloneRequest = (caseID) => {
    console.log(caseID);
    setselectedCaseId(caseID);
    setShowCloneModal(true); //display the clone modal
  };

  return (
    <>
      <CloneModal
        show={showCloneModal}
        {...content.cloneModal}
        onClose={() => setShowCloneModal(false)}
        caseID={selectedCaseId} // id of the case to clone
        onConfirmSuccess={navigatetoCreatePage} //determines what page to go to
      />

      <Box p={{ lg: '2rem', base: '1.5rem' }} bg="lightblue" my="1rem">
        <Heading as="h2" fontSize="sm" fontWeight="medium">
          {requestContent.heading}
        </Heading>
        <RequestItem
          width="100%"
          title={requestContent.nameLabel}
          value={props?.srInfo?.content?.ServiceRequest?.RequestTitle}
        />
        <Flex flexWrap={{ lg: 'nowrap', base: 'wrap' }}>
          <RequestItem
            title={requestContent.dateLabel}
            value={new Date(props?.srInfo?.content?.ServiceRequest?.SubmissionDateTime).toLocaleDateString()}
          />
          <RequestItem
            title={requestContent.IDLabel}
            value={props?.srInfo?.content?.pxInsName}
            tooltip={requestContent.idTooltipContent}
          />
          {/* Visible to GEMS only - SR Number */}
          {props?.srInfo?.content.GemsNotificationID?.length > 0 && (
            <RequestItem
              title={requestContent.SRNumber}
              value={props?.srInfo?.content?.GemsNotificationID}
              tooltip={requestContent.srTooltipContent}
            />
          )}
        </Flex>
        <Box paddingTop="1rem">
          <Text>{requestContent.statusLabel}</Text>
          <PegaCaseBadge status={props.srInfo?.status} />
        </Box>

        <Text paddingY="1rem" fontWeight="light">
          {requestContent.description}
        </Text>
        <Button
          onClick={() => cloneRequest(props.srInfo?.ID)}
          variant="primary"
          data-testid="clone-request"
          minWidth="70px"
          padding="1rem"
        >
          Clone
        </Button>
      </Box>
    </>
  );
};

export default KeyRequestInfo;
